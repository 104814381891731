import emailValidator from "email-validator";

const minNameCharacters = 2;
const maxNameCharacters = 50;

const minPasswordCharacters = 8;
const maxPasswordCharacters = 40;

const minMessageExitFormCharacters = 10;
const maxMessageExitFormCharacters = 1000;

const namePatterns = [
  {
    regex: /^(?=\p{L})[\p{L} ,.'-]+$/u,
    error: "Name must not contain special characters or numbers",
  },
];

const passwordPatterns = [
  {
    regex: /.*[a-z].*/,
    error: "Password must contain at least one lowercase letter",
  },
  {
    regex: /.*[A-Z].*/,
    error: "Password must contain at least one upper letter",
  },
  {
    regex: /.*\d.*/,
    error: "Password must contain at least one digit",
  },
  {
    regex: /.*[!@#$%^&*()_+\-=[\]{};':"|,.<>?/\\].*/,
    error: "Password must contain at least one special character",
  },
];

export function checkEmail(email, isOptional = false) {
  if (isOptional && !email) {
    return { isOk: true };
  }

  if (!email) {
    return {
      error: "Email is empty",
    };
  }

  if (emailValidator.validate(email)) {
    return {
      isOk: true,
    };
  }

  return {
    error: "Email is invalid",
  };
}

export function checkPassword(password) {
  if (!password) {
    return {
      error: "Password is empty",
    };
  }

  if (password.length < minPasswordCharacters) {
    return {
      error: `Password must be at least ${minPasswordCharacters} characters`,
    };
  }

  if (password.length > maxPasswordCharacters) {
    return {
      error: `Password should not exceed ${maxPasswordCharacters} characters`,
    };
  }

  const regexError = checkRegexPatterns(passwordPatterns, password);
  if (regexError) {
    return regexError;
  }

  return {
    isOk: true,
  };
}

export function checkConfirmedPassword(password, confirmedPassword) {
  if (!confirmedPassword) {
    return {
      error: "Confirmed password is empty",
    };
  }

  if (password !== confirmedPassword) {
    return {
      error: "Password and confirmed password are not the same",
    };
  }

  return {
    isOk: true,
  };
}

function checkName(name, isFirtName) {
  const label = isFirtName ? "First name" : "Last name";

  if (!name) {
    return {
      error: `${label} is empty`,
    };
  }

  const regexError = checkRegexPatterns(namePatterns, name);
  if (regexError) {
    return regexError;
  }

  return checkCharactersCount(
    label,
    name,
    minNameCharacters,
    maxNameCharacters
  );
}

export function checkFirstName(firstName) {
  return checkName(firstName, true);
}

export function checkLastName(lastName) {
  return checkName(lastName, false);
}

export function checkMessageFromExitForm(message) {
  if (!message) {
    return { error: "Message is empty" };
  }

  return checkCharactersCount(
    "Message",
    message,
    minMessageExitFormCharacters,
    maxMessageExitFormCharacters
  );
}

function checkCharactersCount(label, text, minCharacters, maxCharacters) {
  if (text.length < minCharacters) {
    return {
      error: `${label} should have ${minCharacters} characters at least`,
    };
  }

  if (text.length > maxCharacters) {
    return {
      error: `${label} should have ${maxCharacters} characters maximum`,
    };
  }

  return { isOk: true };
}

function checkRegexPatterns(regexPatterns, str) {
  for (const { regex, error } of regexPatterns) {
    if (!regex.test(str)) {
      return { error };
    }
  }
}
