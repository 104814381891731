import { useState, useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Tab, { TabState } from "./Tab";
import MoreAboutYou from "./more-about-you/MoreAboutYou";
import GeneralInformation from "./general-information/GeneralInformation";
import MoreAboutYouFooter from "./more-about-you/MoreAboutYouFooter";
import GeneralInformationFooter from "./general-information/GeneralInformationFooter";
import { trackUserPreference } from "../../common/full-story";
import { track } from "../../utils";
import { storeLpcLoginUrl } from "../../common/lpc";
import { changePageTitle } from "../../common/page";
import {
  checkEmail,
  checkFirstName,
  checkLastName,
  checkPassword,
  checkConfirmedPassword,
} from "../../common/field";

const formExitWaitingActivationDelay =
  process.env.REACT_APP_FORM_EXIT_WAITING_ACTIVATION_DELAY;

const isChrome = !!window.navigator.userAgent.match(/Chrome/i);

if (!formExitWaitingActivationDelay) {
  throw new Error("Form exit waiting activation delay not defined");
}

function isMoreAboutInformationOk({
  companyType,
  countryCode,
  categoryId,
  subCategoryId,
  retailerIds,
}) {
  return (
    companyType &&
    countryCode &&
    Number.isInteger(categoryId) &&
    Number.isInteger(subCategoryId) &&
    retailerIds &&
    retailerIds.length > 1
  );
}

function isGeneralInformationOk({
  email,
  firstName,
  lastName,
  password,
  confirmedPassword,
  captchaUnblockingCode,
}) {
  return (
    checkEmail(email).isOk &&
    checkFirstName(firstName).isOk &&
    checkLastName(lastName).isOk &&
    checkPassword(password).isOk &&
    checkConfirmedPassword(password, confirmedPassword).isOk &&
    captchaUnblockingCode
  );
}

function getCorrectLpcLoginUrl(lpcLoginUrl, searchParams) {
  const url = searchParams.get("lpc-login-url");
  return url ? url : lpcLoginUrl;
}

export default function SignupForm({
  resetFormExitReferenceTime,
  onAccountCreated,
}) {
  const { lpcLoginUrl } = useParams();
  const [searchParams] = useSearchParams();

  const [moreAboutYouState, setMoreAboutYouState] = useState(TabState.DISABLED);
  const [moreAboutYouDataOk, setMoreAboutYouDataOk] = useState(false);
  const [generalInformationState, setGeneralInformationState] = useState(
    TabState.DISABLED
  );
  const [generalInformationData, setGeneralInformationData] = useState({
    retailersForSelectedCountry: [],
  });
  const [generalInformationDataOk, setGeneralInformationDataOk] =
    useState(false);
  const [generalInformationTooltip, setGeneralInformationTooltip] =
    useState(null);
  const [disableMoreAboutYou, setDisabledMoreAboutYou] = useState(false);
  const [showMoreAboutYou, setShowMoreAboutYou] = useState(true);
  const [showGeneralInformation, setShowGeneralInformation] = useState(false);
  const [resetCaptcha, setResectCaptcha] = useState(0);
  const [moreAboutYouData, setMoreAboutYouData] = useState({});
  const [fixOffsetScroll, setFixOffsetScroll] = useState(false);

  useEffect(() => {
    changePageTitle("Sign up for Live Price Check");

    const correctLpcLoginUrl = getCorrectLpcLoginUrl(lpcLoginUrl, searchParams);
    if (correctLpcLoginUrl) {
      storeLpcLoginUrl(correctLpcLoginUrl);
    }

    const isFromSignin = searchParams.get("from-signin") === "true";

    track("Sign up form opened", { isFromSignin }).finally(() => {
      /*Do not use navigate from router or window.location.href because this reloads page.
        lpcLoginUrl and searchParams will be changed and we will have a duplicated event */
      window.history.replaceState(null, "", "/#signup");
    });
  }, [lpcLoginUrl, searchParams]);

  useEffect(() => {
    const moreAboutYouDataOk = isMoreAboutInformationOk(moreAboutYouData);
    setMoreAboutYouDataOk(moreAboutYouDataOk);

    if (showMoreAboutYou) {
      setMoreAboutYouState(TabState.SELECTED);

      if (moreAboutYouDataOk) {
        setGeneralInformationState(TabState.UNSELECTED);
        setGeneralInformationTooltip(null);
      } else {
        setGeneralInformationState(TabState.DISABLED);
        setGeneralInformationTooltip("Please configure the tracker");
      }
    }
  }, [showMoreAboutYou, moreAboutYouData, setMoreAboutYouDataOk]);

  useEffect(
    () =>
      setGeneralInformationDataOk(
        isGeneralInformationOk(generalInformationData)
      ),
    [generalInformationData]
  );

  useEffect(() => {
    if (showGeneralInformation) {
      setMoreAboutYouState(TabState.UNSELECTED);
      setGeneralInformationState(TabState.SELECTED);
      setGeneralInformationTooltip(null);
    }
  }, [showGeneralInformation]);

  const onMoreAboutYouData = useCallback(
    (data) => setMoreAboutYouData(data),
    []
  );

  const onGoBackButtonClicked = useCallback(() => {
    setShowMoreAboutYou(true);
    setShowGeneralInformation(false);
  }, []);

  const onSelectMoreAboutYouTab = useCallback(
    () => onGoBackButtonClicked(),
    [onGoBackButtonClicked]
  );

  const onNextButtonClicked = useCallback(() => {
    if (moreAboutYouData) {
      trackUserPreference(moreAboutYouData);
    }

    setShowMoreAboutYou(false);
    setShowGeneralInformation(true);
    track("Next button clicked on signup form retailer selection page");
  }, [moreAboutYouData]);

  const onSelectGeneralInformation = useCallback(
    () => onNextButtonClicked(),
    [onNextButtonClicked]
  );

  const onGeneralInformationData = useCallback(
    (data) => setGeneralInformationData(data),
    []
  );

  const onBeginCreatingAccount = useCallback(
    () => setDisabledMoreAboutYou(true),
    []
  );

  const onEndCreatingAccount = useCallback(
    ({ success, email }) => {
      setDisabledMoreAboutYou(false);
      if (success) {
        onAccountCreated(email);
      } else {
        setResectCaptcha((resetCaptcha) => resetCaptcha + 1);
        setGeneralInformationData({});
      }
    },
    [onAccountCreated]
  );

  const onDisplayRetailers = useCallback((display) => {
    if (isChrome) {
      setFixOffsetScroll(display);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="text-black font-semibold text-[24px] mb-6 leading-[34px] w-[400px] pt-8">
          Let's personalize your price tracker
        </div>
        <div className="flex gap-x-6 w-[400px]">
          <Tab
            tabState={moreAboutYouState}
            areFieldsValid={moreAboutYouDataOk}
            onSelect={onSelectMoreAboutYouTab}
            onActivity={resetFormExitReferenceTime}
          >
            1. Configure the tracker
          </Tab>
          <Tab
            tabState={generalInformationState}
            areFieldsValid={generalInformationDataOk}
            tooltip={generalInformationTooltip}
            onSelect={onSelectGeneralInformation}
            onActivity={resetFormExitReferenceTime}
          >
            2. Save your profile
          </Tab>
        </div>
        <div className="flex justify-center w-full h-[510px] overflow-y-auto">
          <div
            className="w-[400px]"
            style={{ paddingLeft: fixOffsetScroll ? "5px" : "0px" }}
          >
            <MoreAboutYou
              disabled={disableMoreAboutYou}
              visible={showMoreAboutYou}
              onData={onMoreAboutYouData}
              onActivity={resetFormExitReferenceTime}
              onDisplayRetailers={onDisplayRetailers}
            />
            <GeneralInformation
              onData={onGeneralInformationData}
              visible={showGeneralInformation}
              resetCaptcha={resetCaptcha}
              onActivity={resetFormExitReferenceTime}
            />
          </div>
        </div>
      </div>

      {showMoreAboutYou ? (
        <MoreAboutYouFooter
          disabled={!moreAboutYouDataOk}
          onNextButtonClicked={onNextButtonClicked}
          onActivity={resetFormExitReferenceTime}
        />
      ) : null}
      {showGeneralInformation ? (
        <GeneralInformationFooter
          generalInformationData={generalInformationData}
          moreAboutYouData={moreAboutYouData}
          disabled={!generalInformationDataOk}
          onBeginCreatingAccount={onBeginCreatingAccount}
          onEndCreatingAccount={onEndCreatingAccount}
          onActivity={resetFormExitReferenceTime}
        />
      ) : null}
    </>
  );
}
