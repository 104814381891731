import { useCallback, useState, useRef, useEffect } from "react";
import { Input, Tooltip, Spin } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import color from "../../../common/color";
import {
  checkConfirmedPassword,
  checkEmail,
  checkFirstName,
  checkLastName,
  checkPassword,
} from "../../../common/field";
import PasswordStrengthChecker from "./PasswordStrengthChecker";
import PasswordField from "./PasswordField";
import PasswordHelp from "./PasswordHelp";
import {
  updateUserProperties,
  updateUserPropertiesLater,
} from "../../../common/full-story";

function buildGeneralInformationStyle(visible) {
  const style = { color: color.DarkGrey2 };
  if (!visible) {
    style.display = "none";
  }

  return style;
}

function isGeneralInformationOk({
  email,
  firstName,
  lastName,
  password,
  confirmedPassword,
}) {
  return (
    checkEmail(email).isOk &&
    checkFirstName(firstName).isOk &&
    checkLastName(lastName).isOk &&
    checkPassword(password).isOk &&
    checkConfirmedPassword(password, confirmedPassword).isOk
  );
}

export default function GeneralInformation({
  onData,
  visible,
  disabled,
  resetCaptcha,
  onActivity,
}) {
  const recaptchaRef = useRef();

  const [emailStatus, setEmailStatus] = useState(null);
  const [email, setEmail] = useState("");

  const [passwordStatus, setPasswordStatus] = useState(null);
  const [password, setPassword] = useState("");

  const [confirmedPasswordStatus, setConfirmedPasswordStatus] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const [firstNameStatus, setFirstNameStatus] = useState(null);
  const [firstName, setFirstName] = useState("");

  const [lastNameStatus, setLastNameStatus] = useState(null);
  const [lastName, setLastName] = useState("");
  const [showPasswordStrengthChecker, setShowPasswordStrengthChecker] =
    useState(false);

  const [captchaUnblockingCode, setCaptchaUnblockingCode] = useState(null);

  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [hasCaptchaLoaded, setHasCaptchaLoaded] = useState(false);
  const [displayPasswordHelp, setDisplayPasswordHelp] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  useEffect(() => {
    if (Number.isInteger(resetCaptcha) && resetCaptcha !== 0) {
      setShowCaptcha(false);
      setTimeout(() => setShowCaptcha(true), 100);
    }
  }, [resetCaptcha]);

  useEffect(() => {
    if (disabled) {
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode: null,
      });
    } else if (
      !hasCaptchaLoaded &&
      isGeneralInformationOk({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
      })
    ) {
      setLoadingCaptcha(true);
    }
  }, [
    hasCaptchaLoaded,
    disabled,
    email,
    firstName,
    lastName,
    password,
    confirmedPassword,
    onData,
  ]);

  const onEmailChanged = useCallback(
    (event) => {
      onActivity();
      const email = event.target.value;
      setEmail(email);
      setEmailStatus(checkEmail(email).isOk ? null : "error");
      updateUserPropertiesLater({ email, firstName, lastName });
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode,
      });
    },
    [
      firstName,
      lastName,
      password,
      confirmedPassword,
      captchaUnblockingCode,
      onData,
      onActivity,
    ]
  );

  const onPasswordChanged = useCallback(
    (event) => {
      onActivity();
      const password = event.target.value;
      setPassword(password);
      setShowPasswordStrengthChecker(true);
      setPasswordStatus(checkPassword(password).isOk ? null : "error");
      updateUserPropertiesLater({ email, firstName, lastName });
      if (confirmedPassword) {
        setConfirmedPasswordStatus(
          checkConfirmedPassword(password, confirmedPassword).isOk
            ? null
            : "error"
        );
      }
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode,
      });
    },
    [
      email,
      firstName,
      lastName,
      confirmedPassword,
      captchaUnblockingCode,
      onData,
      onActivity,
    ]
  );

  const onConfirmedPasswordChanged = useCallback(
    (event) => {
      onActivity();
      const confirmedPassword = event.target.value;
      setConfirmedPassword(confirmedPassword);
      setConfirmedPasswordStatus(
        checkConfirmedPassword(password, confirmedPassword).isOk
          ? null
          : "error"
      );
      updateUserPropertiesLater({ email, firstName, lastName });
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode,
      });
    },
    [
      email,
      password,
      firstName,
      lastName,
      captchaUnblockingCode,
      onData,
      onActivity,
    ]
  );

  const onEmailClicked = useCallback(() => {
    onActivity();
    setEmailStatus(checkEmail(email).isOk ? null : "error");
  }, [email, onActivity]);

  const onPasswordClicked = useCallback(() => {
    onActivity();
    setPasswordStatus(checkPassword(password).isOk ? null : "error");
    setShowPasswordStrengthChecker(true);
  }, [password, onActivity]);

  const onConfirmedPasswordClicked = useCallback(() => {
    onActivity();
    setConfirmedPasswordStatus(
      checkConfirmedPassword(password, confirmedPassword).isOk ? null : "error"
    );
  }, [password, confirmedPassword, onActivity]);

  const onFirstNameChanged = useCallback(
    (event) => {
      onActivity();
      const firstName = event.target.value;
      setFirstName(firstName);
      setFirstNameStatus(checkFirstName(firstName).isOk ? null : "error");
      updateUserPropertiesLater({ email, firstName, lastName });
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode,
      });
    },
    [
      email,
      lastName,
      password,
      confirmedPassword,
      captchaUnblockingCode,
      onData,
      onActivity,
    ]
  );

  const onFirstNameClicked = useCallback(() => {
    onActivity();
    setFirstNameStatus(checkFirstName(firstName).isOk ? null : "error");
  }, [setFirstNameStatus, firstName, onActivity]);

  const onLastNameChanged = useCallback(
    (event) => {
      onActivity();
      const lastName = event.target.value;
      setLastName(lastName);
      setLastNameStatus(checkLastName(lastName).isOk ? null : "error");
      updateUserPropertiesLater({ email, firstName, lastName });
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode,
      });
    },
    [
      email,
      firstName,
      password,
      confirmedPassword,
      captchaUnblockingCode,
      onData,
      onActivity,
    ]
  );

  const onLastNameClicked = useCallback(() => {
    onActivity();
    setLastNameStatus(checkLastName(lastName).isOk ? null : "error");
  }, [setLastNameStatus, lastName, onActivity]);

  const onCaptchaChanged = useCallback(
    (captchaUnblockingCode) => {
      onActivity();
      setCaptchaUnblockingCode(captchaUnblockingCode);
      onData({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
        captchaUnblockingCode,
      });
    },
    [
      email,
      firstName,
      lastName,
      password,
      confirmedPassword,
      onData,
      onActivity,
    ]
  );

  const onCaptchaLoadingDone = useCallback(() => {
    onActivity();
    setLoadingCaptcha(false);
    setHasCaptchaLoaded(true);
  }, [onActivity]);

  function buildTooltipEmailTitle() {
    const result = checkEmail(email);
    return result.isOk ? null : result.error;
  }

  function buildTooltipPasswordTitle() {
    const result = checkPassword(password);
    return result.isOk ? null : result.error;
  }

  function buildTooltipConfirmedPasswordTitle() {
    const result = checkConfirmedPassword(password, confirmedPassword);
    return result.isOk ? null : result.error;
  }

  function isTooltipOpened(status) {
    return status && visible;
  }

  const checkFirstNameResult = checkFirstName(firstName);
  const checkLastNameResult = checkLastName(lastName);

  const onInfoPasswordMouseEnter = useCallback(() => {
    onActivity();
    setDisplayPasswordHelp(true);
  }, [onActivity]);

  const onInfoPasswordMouseLeave = useCallback(() => {
    onActivity();
    setDisplayPasswordHelp(false);
  }, [onActivity]);

  useEffect(() => {
    if (
      !disabled &&
      isGeneralInformationOk({
        email,
        firstName,
        lastName,
        password,
        confirmedPassword,
      })
    ) {
      setShowCaptcha(true);
    }
  }, [disabled, email, firstName, lastName, password, confirmedPassword]);

  const trackUserProperties = useCallback(
    () => updateUserProperties({ email, firstName, lastName }),
    [email, firstName, lastName]
  );

  return (
    <div
      className="font-medium text-[16px]"
      style={buildGeneralInformationStyle(visible)}
    >
      <div className="mt-8">
        <div>Your email address</div>
        <div className="mt-1">
          <Tooltip
            placement="topRight"
            title={buildTooltipEmailTitle()}
            open={isTooltipOpened(emailStatus)}
          >
            <Input
              className="rounded-sm text-[16px]"
              size="large"
              status={emailStatus}
              value={email}
              onChange={onEmailChanged}
              onClick={onEmailClicked}
              autoComplete="email"
              onBlur={trackUserProperties}
            />
          </Tooltip>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex items-center">
          <div className="mr-2">Password</div>
          <div className="flex items-center">
            <InfoCircleOutlined
              className="cursor-pointer"
              onMouseEnter={onInfoPasswordMouseEnter}
              onMouseLeave={onInfoPasswordMouseLeave}
            />
          </div>
        </div>

        <div className="mt-1">
          <PasswordField
            tootltip={buildTooltipPasswordTitle()}
            tootltipOpened={isTooltipOpened(passwordStatus)}
            status={passwordStatus}
            value={password}
            addOkIcon={true}
            onChange={onPasswordChanged}
            onClick={onPasswordClicked}
            onBlur={trackUserProperties}
          />
        </div>
        {showPasswordStrengthChecker ? (
          <div className="mt-1">
            <PasswordStrengthChecker password={password} />
          </div>
        ) : null}
      </div>
      <PasswordHelp display={displayPasswordHelp} />
      <div className="mt-8">
        <div>Confirm password</div>
        <div className="mt-1">
          <PasswordField
            tootltip={buildTooltipConfirmedPasswordTitle()}
            tootltipOpened={isTooltipOpened(confirmedPasswordStatus)}
            status={confirmedPasswordStatus}
            value={confirmedPassword}
            addOkIcon={!passwordStatus}
            onChange={onConfirmedPasswordChanged}
            onClick={onConfirmedPasswordClicked}
            onBlur={trackUserProperties}
          />
        </div>
      </div>
      <div className="mt-8 flex justify-between">
        <div className="w-full mr-[15px]">
          <div>First Name</div>
          <div className="mt-1">
            <Tooltip
              placement="topRight"
              title={
                checkFirstNameResult.isOk ? null : checkFirstNameResult.error
              }
              open={isTooltipOpened(firstNameStatus)}
            >
              <Input
                className="rounded-sm text-[16px] w-full"
                size="large"
                status={firstNameStatus}
                value={firstName}
                onChange={onFirstNameChanged}
                onClick={onFirstNameClicked}
                autoComplete="given-name"
                onBlur={trackUserProperties}
              />
            </Tooltip>
          </div>
        </div>
        <div className="w-full ml-[15px]">
          <div>Last Name</div>
          <div className="mt-1">
            <Tooltip
              placement="topRight"
              title={
                checkLastNameResult.isOk ? null : checkLastNameResult.error
              }
              open={isTooltipOpened(lastNameStatus)}
            >
              <Input
                className="rounded-sm text-[16px]"
                size="large"
                status={lastNameStatus}
                value={lastName}
                onChange={onLastNameChanged}
                onClick={onLastNameClicked}
                autoComplete="family-name"
                onBlur={trackUserProperties}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {showCaptcha ? (
        <div className="mt-5">
          {loadingCaptcha ? (
            <div className="flex w-full items-center">
              <div className="mr-4">Loading captcha</div>
              <div>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
            </div>
          ) : null}
          <ReCAPTCHA
            asyncScriptOnLoad={onCaptchaLoadingDone}
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
            onChange={onCaptchaChanged}
          />
        </div>
      ) : null}
    </div>
  );
}
